/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~animate.css/animate.css";
// @font-face {
//   font-family: 'CaviarDreams';
//   src: url('assets/font/CaviarDreams.ttf');
// }
// :root {
//   --ion-font-family: CaviarDreams;
//   }
@font-face {
  font-family: "OpenSans-light";
  src: url("assets/font/Opensans/OpenSans-Light.ttf");
}
@font-face {
  font-family: "OpenSans-reg";
  src: url("assets/font/Opensans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans-bold";
  src: url("assets/font/Opensans/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "OpenSans-semi";
  src: url("assets/font/Opensans/OpenSans-bold.ttf");
}

@font-face {
  font-family: "mons-light";
  src: url("assets/font/Montserrat/static/Montserrat-Light.ttf");
}
@font-face {
  font-family: "mons-reg";
  src: url("assets/font/Montserrat/static/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "mons-bold";
  src: url("assets/font/Montserrat/static/Montserrat-Black.ttf");
}
@font-face {
  font-family: "mons-semibold";
  src: url("assets/font/Montserrat/static/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "mons-medbold";
  src: url("assets/font/Montserrat/static/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "mons-semi";
  src: url("assets/font/Montserrat/static/Montserrat-Medium.ttf");
}

// roboto
@font-face {
  font-family: "robo-light";
  src: url("assets/font/Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: "robo-reg";
  src: url("assets/font/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "robo-bold";
  src: url("assets/font/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "robo-semibold";
  src: url("assets/font/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "robo-medbold";
  src: url("assets/font/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "robo-semi";
  src: url("assets/font/Roboto/Roboto-Medium.ttf");
}

:root {
  --ion-font-family: "robo-reg";
}
// @font-face {
//   font-family: 'Ubuntu';
//   src: url('assets/font/Ubuntu/Ubuntu-Regular.ttf');
// }
// :root {
//   --ion-font-family: 'Ubuntu';
//   }
// @font-face {
//   font-family: 'Roboto';
//   src: url('assets/font/Roboto-Regular.ttf');
// }
// :root {
//   --ion-font-family: 'Roboto';
//   }
.myaction-sheet {
  --backdrop-opacity: 0.6;
  --button-background: transparent;
  --button-color: black;
  --color: black;
  // --height:70vh;
  // --max-height:70vh;
}
//   .myaction-sheet .action-sheet .button
//   {
//       padding: 0 10px;
//       text-align: left !important;
//   }
//   .myaction-sheet .action-sheet .button .text-action
//   {
//       margin-left: 30px;
//   }

//   .myaction-sheet .action-sheet .button .text-width
//   {
//       margin-left: 50px;
//   }
.add_new_address_ALERT {
  // --backdrop-opacity	Opacity of the backdrop
  // --background:#eee;
  --height: 45vh;
  --max-height: 45vh;
  --max-width: 70vw;
  --width: 70vw;
  color: var(--ion-color-dark);
}
.sc-ion-searchbar-ios-h {
  --background: #f2f4f5;
  --border-radius: 10px;
  --box-shadow: none;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  // margin-top: -5px;
  height: 70px;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.searchbar-input.sc-ion-searchbar-ios {
  --background: #f2f4f59a;
  // margin-bottom: 5px;
  height: 50px;
  font-size: 17px;
  font-weight: 500;
  color: var(--ion-color-dark);
}
.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 15px;
  top: 13px;
  position: absolute;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  color: var(--ion-color-dark);
}
.searchbar-left-aligned.sc-ion-searchbar-ios-h
  .searchbar-input.sc-ion-searchbar-ios {
  padding-left: unset;
  -webkit-padding-start: 26px;
  padding-inline-start: 50px;
}
.searchbar-clear-button.sc-ion-searchbar-ios {
  right: 0;
  top: 10px;
  background-position: center;
  position: absolute;
  width: 30px;
  height: 100%;
  border: 0;
  background-color: transparent;
}
.customize_pickupModel .modal-wrapper {
  background: #ecececab;
  backdrop-filter: blur(5px);
}

.APP_toast {
  --border-radius: 20px;
  padding: 14px;
  transition: background-color 0s ease 0s, opacity 100ms linear 0s;
  // --background:var(--ion-color-danger);
  // --background: linear-gradient(45deg, var(--ion-color-primary),var(--ion-color-secondary));
  // --color:var(--ion-color-dark-contast);
  // --button-color: #ffffff;
  // font-weight: 600;
}

.message_POP {
  --width: 100%;
  --height: 100%;
  // --background: transparent;
  --background: #2f2f2f52;
  backdrop-filter: blur(8px);
 
}
// .message_POP .modal-wrapper {
//   background: #ecececab;
//   backdrop-filter: blur(5px);
// }
// .thankYOU{
//   --background: transparent;
//   --box-shadow:none;

//   app-thankyou{
//     width: 300px;
//   height: 300px;
//   margin: auto;
//   // transform: translateY(15%);
//   border-radius: 10px;

//   }
// }
// .thankYOU::part(content) {
//   width: 100%;
//   height: 100%;
//   display: flex;

// }
.giftModal {
  background: #2dd36f2c;
  --box-shadow: none;
}
.datetimeModal {
  --background: rgba(170, 255, 204, 0.341);
  --box-shadow: none;
  app-date-time {
    width: 100%;
    height: 100%;
    margin: auto;
    // transform: translateY(15%);
    // border-radius: 10px;
  }
}
.datetimeModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}
.reserveModal {
  --background: transparent;
  --box-shadow: none;

  app-add-reservation {
    width: 100%;
    height: 100%;
    margin: auto;
    // transform: translateY(15%);
    // border-radius: 10px;
  }
}
.reserveModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}
.QRModal {
  --background: transparent;
  --box-shadow: none;

  app-user-qr {
    width: 300px;
    height: 350px;
    margin: auto;
    // transform: translateY(15%);
    border-radius: 10px;
  }
}
.QRModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}

.addReviewModal {
  --background: transparent;
  --box-shadow: none;
  app-add-review {
    width: 300px;
    height: 500px;
    // transform: translateY(15%);
    margin: auto;
    border-radius: 10px;
  }
}
.addReviewModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}

.pointsModal {
  --background: transparent;
  --box-shadow: none;

  app-points-redeem {
    width: 300px;
    height: 500px;
    margin: auto;
    // transform: translateY(15%);
    border-radius: 10px;
  }
}
.pointsModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}
.stylistModal {
  --background: transparent;
  --box-shadow: none;

  app-stylists {
    width: 300px;
    height: 500px;
    margin: auto;
    transform: translateY(15%);
    border-radius: 10px;
  }
}
.dateModal {
  --background: transparent;
  --box-shadow: none;

  app-date-time {
    width: 320px;
    height: 550px;
    margin: auto;
    border-radius: 10px;
  }
}
.dateModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}

.cartPop {
  app-cart {
    width: auto;
    height: auto;
  }
}
.custom_loading {
  .loading-wrapper {
    background: transparent;
    box-shadow: none;
  }
  ion-spinner {
    display: none !important;
  }
  img {
    width: 100% !important;
    // animation-name: animateMe;
    // animation-delay: 0.1s;
    // animation-direction: alternate;
    // animation-duration: 2s;
    // animation-iteration-count: infinite;
  }
  // @keyframes animateMe {
  //   0%{
  //     position: absolute;
  //     top: 0px;
  //     left: 0;
  //     right: 0;
  //   }
  //   20%{
  //     position: absolute;
  //     top: 20%;
  //     left: 0;
  //     right: 0;
  //   }
  //   40%{
  //     position: absolute;
  //     top: 40%;
  //     left: 0;
  //     right: 0;
  //   }
  // 60%{
  //   position: absolute;
  //   top: 60%;
  //   left: 0;
  //   right: 0;
  // }
  //   80%{
  //     position: absolute;
  //     top: 80%;
  //     left: 0;
  //     right: 0;
  //   }
  //   100%{
  //     position: absolute;
  //     top: 100%;
  //     left: 0;
  //     right: 0;
  //   }

  // }
}
.tipModal {
  --background: transparent;
  --box-shadow: none;
  app-tip {
    height: 270px;
    width: 320px;
    // transform: translateY(15%);
    margin: auto;
    border-radius: 20px;
  }
}
.tipModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}
// --background:#cccccc20;
// margin:auto;
// width:80vw;
// height:80vh;
// backdrop-filter: blur(10px);
@media only screen and (min-width: 700px) {
  .delivery_modal {
    --width: 500px;
  }
  .trackingModal {
    --height: 80%;
    --width: 80%;
    //   --background: transparent;
    //   --box-shadow:none;
    //   app-photo-viewer{
    //     width: 90% !important;
    //   height: 90% !important;
    //   margin: auto !important;
    //   border-radius: 10px;

    // }
  }
  .tipModal {
    --background: transparent;
    --box-shadow: none;
    app-tip {
      height: 370px !important;
      width: 420px !important;
      margin: auto;
      border-radius: 20px;
    }
  }

  .addReviewModal {
    --background: transparent;
    --box-shadow: none;
    app-add-review {
      width: 340px;
      height: 580px;
      margin: auto;
      border-radius: 10px;
    }
  }

  .dateModal {
    --background: transparent;
    --box-shadow: none;

    app-date-time {
      width: 360px;
      height: 620px;
      margin: auto;
      border-radius: 10px;
    }
  }

  .galleryModal {
    --background: transparent;
    --box-shadow: none;
    --width: 90% !important;
    --height: 90% !important;
    app-photo-viewer {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 10px;
    }
  }
  .dineTable {
    --background: transparent;
    --box-shadow: none;
    --width: 90% !important;
    --height: 90% !important;
    app-dinein-table {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 10px;
    }
  }
  .dine_inModal {
    --background: transparent;
    --box-shadow: none;
    --width: 100% !important;
    --height: 100% !important;
    app-hold-order-menu {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 10px;
    }
  }
  .holdOrder_modal {
    --background: transparent;
    --box-shadow: none;
    --width: 90% !important;
    --height: 90% !important;
    app-all-hold-orders {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 10px;
    }
  }
  .buy-gift-modal {
    --background: transparent;
    --box-shadow: none;
    --width: 100% !important;
    --height: 100% !important;
    app-buy-giftcard {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 10px;
    }
  }
  .branch_modal {
    --width: 90% !important;
    --height: 90% !important;
    app-select-branch {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      border-radius: 15px;
    }
  }
  .itemPreviewPage {
    // --background: transparent;
    // --box-shadow:none;
    // --width: 90% !important;
    --height: 90% !important;
    app-item-preview {
      //   width: 90% !important;
      // height: 90% !important;
      margin: auto !important;
      // border-radius: 10px;
    }
  }
  .customize_pickupModel {
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    --width: 400px !important;
    app-pickup-time {
      margin: auto !important;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .navbar {
    padding: 10px;
  }
  // .itemPreviewPage{

  // }

  .itemPreviewPage::part(content) {
    width: 500px;
    // height: 80vh;
    border-radius: 10px;
  }
}

.noEvents {
  pointer-events: none !important;
  background: #eee !important;
  color: #9d9c9c !important;
}
.readonly {
  font-size: 6px !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;

  scrollbar-color: rgb(200, 200, 200) rgb(249, 245, 245);
}

// .modal {
//   display: block !important;
// }

// *::-webkit-scrollbar {
//   width: 5px;
//   height: 10px;
// }

// *::-webkit-scrollbar-track {
//   background: rgb(249, 245, 245);
// }

// *::-webkit-scrollbar-thumb {
//   background: rgb(200, 200, 200);
//   border-radius: 20px;
// }

// *::-webkit-scrollbar-thumb:hover {
//   background: rgb(249, 245, 245);
// }

// Font Size Class
// --------------------------------------------

.fs-80 {
  font-size: 80px !important;
}

.fs-62 {
  font-size: 62px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-58 {
  font-size: 58px !important;
}

.fs-56 {
  font-size: 56px !important;
}

.fs-54 {
  font-size: 54px !important;
}

.fs-52 {
  font-size: 52px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-28 {
  font-size: 28px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-20 {
  font-size: 20px !important;
}
